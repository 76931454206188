import * as React from "react"

const FindingAGuarantor = () => (
  <section className="section p-0 text-left">
    <div className="container px-0">
      <p className="lead-2">
        It’s easier to find a guarantor than you think. Below are the answers to
        the most commonly asked questions that you might find useful.
      </p>
      <div className="accordion accordion-light" id="accordion">
        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-1"
              className="collapsed"
              aria-expanded="false"
            >
              What is a guarantor?
            </a>
          </h4>
          <div id="collapse-1" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                A guarantor is anyone who trusts you. They are someone who is
                ready to back you up and make loan repayments when you can’t.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-2"
              className="collapsed"
              aria-expanded="false"
            >
              Why do I need a guarantor?
            </a>
          </h4>
          <div id="collapse-2" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Not everyone has a perfect credit history. By finding a
                guarantor, you allow us to offer you a loan that is affordable.
              </p>
              <p className="lead-2">
                If there is someone that trusts you, then we can trust you too.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-3"
              className="collapsed"
              aria-expanded="false"
            >
              Who can be a guarantor?
            </a>
          </h4>
          <div id="collapse-3" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Pretty much anyone can be a guarantor. A friend, a family
                member, a colleague, you name it. The guarantor should be
                someone who knows you well, and you should trust each other.
              </p>
              <p className="lead-2">
                Whoever you are thinking about, remember, that they should meet
                this criteria:
              </p>
              <ul>
                <li>Aged at least 18 years old</li>
                <li>Be a resident in Arizona</li>
                <li>Have a social security number</li>
                <li>
                  Have a regular income, and be able to afford your loan monthly
                  repayment if you don’t pay
                </li>
                <li>Have a good credit score</li>
                <li>Have a US bank account where we can deposit your loan</li>
              </ul>
              <p className="lead-2">
                The guarantor doesn’t need to be a home-owner, however, we
                usually recommend first asking someone who does own their home
                as they are more likely to pass our criteria.
              </p>
              <p className="lead-2">
                Also, it’s important to choose someone you trust and who knows
                you well. Remember that the loan proceeds will be disbursed to
                your guarantor’s bank account, and it will be their
                responsibility to give the funds to you.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-4"
              className="collapsed"
              aria-expanded="false"
            >
              Do I need a guarantor to start the loan application?
            </a>
          </h4>
          <div id="collapse-4" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                While having a guarantor will speed up the decision on your loan
                application, it’s not required to have a guarantor when you
                apply.
              </p>
              <p className="lead-2">
                You can start your loan application, get a decision in principle
                and choose your loan offer. We will then send you a link that
                you can share with your prospective guarantor so that they can
                submit their guarantor application.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-5"
              className="collapsed"
              aria-expanded="false"
            >
              How do I ask someone to be a guarantor
            </a>
          </h4>
          <div id="collapse-5" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                “Mum/Dad/Friend… I would like to start on an online course to
                help with my career transition, but I don’t have the money right
                now. Trustic can offer me a loan, if I find someone who trusts
                me and can guarantee that I can make the monthly payments. Would
                you be able to help?”
              </p>
              <p className="lead-2">
                Be transparent about what you need the loan for, how important
                it is for you and that it won’t be possible without their help.
                Making a difference in other people’s lives can be very
                fulfilling, and chances are, they will be honoured that you
                approached them.
              </p>
              <p className="lead-2">
                We would also recommend that you mention the following:
              </p>
              <ul>
                <li>
                  Show that you did your research and chose the best option for
                  your circumstances. Trustic is ideal for someone who has
                  difficulties qualifying for a credit with mainstream lenders
                  and would like to stay away from high-cost credit options.
                </li>
                <li>
                  Let them know that if you keep making timely loan repayments,
                  a loan from Trustic will help you build a positive credit
                  history that may improve your credit score.
                </li>
                <li>
                  Reassure them that you can comfortably afford the monthly
                  payments. And if anything goes wrong, Trustic will always try
                  to come to an arrangement with you first.
                </li>
                <li>
                  Assure them that they will be in control. The loan proceeds
                  will be disbursed to their bank account, so that they can
                  transfer it to you. Trustic will keep them updated on the
                  status of the loan directly.
                </li>
                <li>
                  Acting as a guarantor will not affect their credit score nor
                  their ability to borrow in the future, as long as the loan is
                  in good standing.
                </li>
              </ul>
              <p className="lead-2">
                Honesty is the key. You might be asked why you need a guarantor
                in the first place, which might lead to uncomfortable discussion
                about credit scores. However, once it’s done, you might wonder
                why you were hesitating to talk about it in the first place.
              </p>
              <p className="lead-2">
                We have also created a comprehensive guarantor guide that you
                might want to share with them.
              </p>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Ze7RWbQfS1M"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-6"
              className="collapsed"
              aria-expanded="false"
            >
              Can I complete guarantor application on their behalf?
            </a>
          </h4>
          <div id="collapse-6" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                No. Actually, doing so will be considered a fraud.
              </p>
              <p className="lead-2">
                Trustic is going to send you a link to the guarantor
                application, that the guarantors should complete on their own.
              </p>
              <p className="lead-2">
                Once a guarantor application is completed, and the guarantor is
                conditionally approved, Trustic is going to be in touch with
                them directly to ensure that they understand their
                responsibilities.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-7"
              className="collapsed"
              aria-expanded="false"
            >
              What are you going to ask from my guarantor?
            </a>
          </h4>
          <div id="collapse-7" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Guarantors will have to complete their own application. Don’t
                worry, it’s pretty straightforward and shouldn’t take more than
                5 minutes.
              </p>
              <p className="lead-2">
                We will ask for their personal information and income details.
              </p>
              <p className="lead-2">
                If approved, they will have to provide their bank account that
                Trustic will use to disburse the loan proceeds, and set up a
                payment method. They will be able to review your consumer loan
                agreement and sign the personal guarantee obligation document.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-8"
              className="collapsed"
              aria-expanded="false"
            >
              What is the guarantor’s responsibility?
            </a>
          </h4>
          <div id="collapse-8" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                The guarantor is responsible to make a payment on the loan when
                the borrower cannot.
              </p>
              <p className="lead-2">
                Also, the guarantor is responsible to give the loan proceeds to
                the borrower after the loan is disbursed to their bank account.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-9"
              className="collapsed"
              aria-expanded="false"
            >
              Why does the guarantor need to have a payment method set up?
            </a>
          </h4>
          <div id="collapse-9" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Guarantor is responsible to make a payment when the borrower is
                not able to. We want to make sure that the Guarantor is fully
                set up to do so in order to avoid any unnecessary late payment
                charges.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-10"
              className="collapsed"
              aria-expanded="false"
            >
              How is Guarantor different from Co-signer?
            </a>
          </h4>
          <div id="collapse-10" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Both a Co-Signer and Guarantor allow you to qualify for loan
                terms that otherwise you might not be able to qualify (i.e.
                lower interest rate, higher loan amount, etc.). However, there
                are some differences in responsibilities between the two.
              </p>
              <p className="lead-2">
                You can think about a Co-signer as your co-borrower who jointly
                signs your loan obligation. The co-signer will have the same
                responsibilities on the loan obligation as you, the prime
                borrower, from day 1.
              </p>
              <p className="lead-2">
                The Guarantor becomes responsible for your loan obligation only
                in the event when you, the borrower, cannot make a payment. For
                example, the Guarantor will not sign your consumer loan
                contract, instead they will be signing their own guarantor
                obligation. Also, the loan will not be shown on their credit
                report. Trustic will start reporting the loan on Guarantor’s
                credit report only in the event of borrower defaulting on their
                loan.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-11"
              className="collapsed"
              aria-expanded="false"
            >
              Why do you disburse the loan to the Guarantor?
            </a>
          </h4>
          <div id="collapse-11" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                In order to reduce fraud, we have decided to disburse the loan
                proceeds to the guarantor. Once received, the Guarantor is
                obligated to give the funds to the borrower.
              </p>
              <p className="lead-2">
                Also, we do believe in empowering guarantors by giving them more
                control.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default FindingAGuarantor
