import * as React from "react"

const OurLoans = () => (
  <section className="section p-0 text-left">
    <div className="container px-0">
      <div className="accordion accordion-light" id="accordion">
        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-1"
              className="collapsed"
              aria-expanded="false"
            >
              How much can I borrow?
            </a>
          </h4>
          <div id="collapse-1" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                You can borrow between $1,001 and $3,000.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-2"
              className="collapsed"
              aria-expanded="false"
            >
              How long does it take to repay?
            </a>
          </h4>
          <div id="collapse-2" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Our personal loans are repayable in equal monthly installments
                over 36 months.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-3"
              className="collapsed"
              aria-expanded="false"
            >
              Can I repay early?
            </a>
          </h4>
          <div id="collapse-3" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Yes, you can repay your loan early without incurring any
                penalties nor additional charges.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-4"
              className="collapsed"
              aria-expanded="false"
            >
              What is your APR range?
            </a>
          </h4>
          <div id="collapse-4" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">APR ranges between 9.99% and 35.99%.</p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-5"
              className="collapsed"
              aria-expanded="false"
            >
              Do you charge any fees?
            </a>
          </h4>
          <div id="collapse-5" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                We charge an origination fee of up to 5%.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-6"
              className="collapsed"
              aria-expanded="false"
            >
              What is your typical loan example?
            </a>
          </h4>
          <div id="collapse-6" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                A representative example: You receive a loan of $1,500 repayable
                over 36 monthly installments with an interest rate of 15% and a
                5% origination fee of $75, for an APR of 18.67%. In this
                example, $1,425 will be given to your guarantor as your agent
                and you will make 36 monthly payments of $52.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-7"
              className="collapsed"
              aria-expanded="false"
            >
              What US states your loans are available in?
            </a>
          </h4>
          <div id="collapse-7" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Trustic loans are currently available in the state of Arizona
                only.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default OurLoans
