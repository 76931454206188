import * as React from "react"

const ApplyingForALoan = () => (
  <section className="section p-0 text-left">
    <div className="container px-0">
      <div className="accordion accordion-light" id="accordion">
        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-1"
              className="collapsed"
              aria-expanded="false"
            >
              Who can apply for a loan with Trustic?
            </a>
          </h4>
          <div id="collapse-1" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Anyone who meets the basic criteria below can apply for a loan
                with us:
              </p>
              <ul className="lead-2">
                <li>Aged at least 18 years old</li>
                <li>Be a resident in Arizona</li>
                <li>Have a social security number</li>
                <li>Have a regular income</li>
                <li>Have a US bank account</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-2"
              className="collapsed"
              aria-expanded="false"
            >
              What is the application process for Borrower?
            </a>
          </h4>
          <div id="collapse-2" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                The application process for borrowers is intuitive and shouldn’t
                take more than 10 minutes. We will ask you for the following:
              </p>
              <ul className="lead-2">
                <li>Provide your personal details</li>
                <li>Provide your income details</li>
                <li>Choose your loan offer</li>
                <li>Let us know who your guarantor is</li>
                <li>Confirm your repayment method</li>
                <li>Sign your loan agreement</li>
                <li>Receive funds</li>
              </ul>
              <p className="lead-2">
                The funds are usually disbursed the same business day upon
                approving your loan application if approved before the funding
                cut off time.*
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-3"
              className="collapsed"
              aria-expanded="false"
            >
              What is the application process for Guarantor?
            </a>
          </h4>
          <div id="collapse-3" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Once the borrower has received their loan offer, they will get a
                guarantor application link by email and sms that they can share
                with their prospective guarantor.
              </p>
              <p className="lead-2">
                The prospective guarantor can use that link on their phone or
                desktop to submit required information. The process is fairly
                straightforward and should not take much time.
              </p>
              <p className="lead-2">
                First, we will ask for some personal details (name, address,
                date of birth), as well as your income details. We will run a
                number of automated checks in order to confirm your identity,
                check your credit reports, etc and let you know if you are
                pre-qualified to be a Guarantor. This part takes just a couple
                of minutes and doesn’t affect your credit score.
              </p>
              <p className="lead-2">
                Once you are pre-qualified, we will ask you to submit and verify
                the following additional information:
              </p>
              <ul className="lead-2">
                <li>Provide additional info about your job or income</li>
                <li>Confirm your bank account & repayment method</li>
                <li>Sign your personal guarantee</li>
              </ul>
              <p className="lead-2">
                And that’s it. Once the borrower finalises their process and the
                loan is approved, you will receive the funds onto your bank
                account. It will be your responsibility to give the money to the
                borrower.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-4"
              className="collapsed"
              aria-expanded="false"
            >
              How long does it take?
            </a>
          </h4>
          <div id="collapse-4" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Our applications for borrowers and guarantors shouldn’t take
                more than 10 minutes.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-5"
              className="collapsed"
              aria-expanded="false"
            >
              Do you run credit checks?
            </a>
          </h4>
          <div id="collapse-5" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Yes, we do run credit and identity verification checks for
                borrowers and guarantors.
              </p>
              <p className="lead-2">
                For borrowers, checking the rates doesn’t affect their credit
                score.
              </p>
              <p className="lead-2">
                For guarantors, it doesn’t affect your credit score to apply.
                Also, once the loan is disbursed, it is not reported on your
                credit report as long as the loan is in good standing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ApplyingForALoan
