import * as React from "react"

const BecomingAGuarantor = () => (
  <section className="section p-0 text-left">
    <div className="container px-0">
      <p className="lead-2">
        Congratulations! If you are reading this section, someone has trusted
        you enough to become their guarantor.
      </p>
      <div className="accordion accordion-light" id="accordion">
        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-1"
              className="collapsed"
              aria-expanded="false"
            >
              Why to be a Guarantor?
            </a>
          </h4>
          <div id="collapse-1" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                At Trustic we believe that credit shouldn’t cost an arm and a
                leg, and that everyone should have access to affordable credit.
                However, the traditional ways to assess someone’s
                creditworthiness based on their credit profiles is not working
                for many Americans. Someone didn’t have an opportunity to start
                building their credit history early enough, or simply is too
                young to have it. Someone has too much student debt, doesn't use
                a credit card or simply has got bad financial advice at some
                point in their life. The fact is that many believe that the
                system was stacked against them from the start. They may have a
                stable job or income and act responsibly and still not have
                access to affordable credit.
              </p>
              <p className="lead-2">This is where we believe you can help.</p>
              <p className="lead-2">
                By stepping in as a Guarantor you have an opportunity to make a
                positive change in someone’s life by vouching for them thus
                allowing them to qualify for an affordable credit.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-2"
              className="collapsed"
              aria-expanded="false"
            >
              Who should I guarantee for?
            </a>
          </h4>
          <div id="collapse-2" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                It’s imperative that you know the borrower well before deciding
                to guarantee for them. They do not need to be your family
                member, however, they should be someone you trust.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-3"
              className="collapsed"
              aria-expanded="false"
            >
              What are my obligations as a Guarantor?
            </a>
          </h4>
          <div id="collapse-3" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Essentially, as a Guarantor you are responsible to step in when
                the borrower cannot make payments on their own.
              </p>
              <p className="lead-2">
                Also, we disburse the loan funds onto your bank account. It will
                be your responsibility to give the money to the borrower.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-4"
              className="collapsed"
              aria-expanded="false"
            >
              Does being a Guarantor affect my credit score or ability to
              borrow?
            </a>
          </h4>
          <div id="collapse-4" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                It does not, as long as the borrower continues making regular
                payments on their loan.
              </p>
              <p className="lead-2">
                As you are a Guarantor, the loan is not reported on your credit
                profile, thus it does not affect your credit score nor your
                affordability assessments if you decide to borrow yourself in
                the future.
              </p>
              <p className="lead-2">
                However, if the loan goes into default, we have the right to
                start reporting it for the Guarantor as well and it will show up
                on your credit report.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-9"
              className="collapsed"
              aria-expanded="false"
            >
              What is a Guarantor release?
            </a>
          </h4>
          <div id="collapse-9" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                We want to ensure that our loan product is as accommodating to
                guarantors as possible. Once the borrower makes 12 consecutive
                monthly loan payments on time, the guarantor will be released
                from their guarantor obligation.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-5"
              className="collapsed"
              aria-expanded="false"
            >
              How do I qualify to be a Guarantor?
            </a>
          </h4>
          <div id="collapse-5" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                In order to qualify to be a Guarantor, you must meet the
                following minimum criteria:
              </p>
              <ul>
                <li>Aged at least 18 years old</li>
                <li>Be a resident in Arizona</li>
                <li>Have a social security number</li>
                <li>Have a regular income</li>
                <li>Have a good credit score</li>
                <li>Have a US bank account</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-6"
              className="collapsed"
              aria-expanded="false"
            >
              How will I know what’s going on with the loan after it’s
              disbursed?
            </a>
          </h4>
          <div id="collapse-6" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                We want to make sure that you have full transparency and
                understanding of what’s happening with the loan.
              </p>
              <p className="lead-2">
                From the start, the loan funds are disbursed to your bank
                account. It is your responsibility as a Guarantor to give the
                funds to the borrower.
              </p>
              <p className="lead-2">
                Once the repayments become due, you will receive regular
                updates. If there are any payment delays or any issues
                whatsoever, you will be notified immediately.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-7"
              className="collapsed"
              aria-expanded="false"
            >
              What happens if the borrower cannot make payments?
            </a>
          </h4>
          <div id="collapse-7" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                You will be notified immediately if there are any delays in
                receiving the due loan payments. If the borrower is not able to
                make the payment(s), it is your responsibility as a Guarantor to
                step in.
              </p>
              <p className="lead-2">
                If the borrower continues having repayment issues, you will be
                presented with several options as detailed in your personal
                guarantee document, including taking over the loan payments or
                accelerating the loan.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <h4 className="card-title">
            <a
              data-toggle="collapse"
              href="#collapse-8"
              className="collapsed"
              aria-expanded="false"
            >
              What is the Guarantor application process?
            </a>
          </h4>
          <div id="collapse-8" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <p className="lead-2">
                Once you agreed to act as a Guarantor, a Borrower will forward
                you a guarantor application link for you to complete.
              </p>
              <p className="lead-2">
                First, we will ask you for some personal details (name, address,
                date of birth), as well as your income details. We will run a
                number of automated checks in order to confirm your identity,
                check your credit reports, etc and let you know if you are
                pre-qualified to be a Guarantor. This part takes just a couple
                of minutes and doesn’t affect your credit score.
              </p>
              <p className="lead-2">
                Once you are pre-qualified, we will ask you to submit and verify
                the following additional information:
              </p>
              <ul>
                <li>Provide additional info about your job or income</li>
                <li>Confirm your bank account & repayment method</li>
                <li>Sign your personal guarantee</li>
              </ul>
              <p className="lead-2">
                And that’s it. Once the borrower finalises their process and the
                loan is approved, you will receive the funds onto your bank
                account. It will be your responsibility to give the money to the
                borrower.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default BecomingAGuarantor
