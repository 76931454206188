import * as React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FindingAGuarantor from "../components/finding-a-guarantor"
import BecomingAGuarantor from "../components/becoming-a-guarantor"
import ApplyingForALoan from "../components/applying-for-a-loan"
import OurLoans from "../components/our-loans"

const Hub = () => {
  return (
    <>
      <Layout
        headerComponent={
          <header className="header pt-8 pb-4 text-white bg-dark text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <h1>Q&A Hub</h1>
                </div>
              </div>
            </div>
          </header>
        }
      >
        <section className="section py-7">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto text-center">
                <ul className="nav nav-tabs-outline nav-separated mx-n2 mx-md-0 mb-3 mb-md-7 mt-n6 mt-md-0 justify-content-start">
                  <li className="nav-item pl-md-0 pr-md-0">
                    <a
                      className="nav-link fs-16 active"
                      data-toggle="tab"
                      href={`#finding-a-guarantor`}
                      style={{ borderRadius: "2px" }}
                    >
                      Finding a Guarantor
                    </a>
                  </li>
                  <li className="nav-item pr-md-0">
                    <a
                      className="nav-link fs-16"
                      data-toggle="tab"
                      href={`#becoming-a-guarantor`}
                      style={{ borderRadius: "2px" }}
                    >
                      Becoming a Guarantor
                    </a>
                  </li>
                  <li className="nav-item pr-md-0">
                    <a
                      className="nav-link fs-16"
                      data-toggle="tab"
                      href={`#applying-for-a-loan`}
                      style={{ borderRadius: "2px" }}
                    >
                      Applying for a Loan
                    </a>
                  </li>
                  <li className="nav-item pr-md-0">
                    <a
                      className="nav-link fs-16"
                      data-toggle="tab"
                      href={`#our-loans`}
                      style={{ borderRadius: "2px" }}
                    >
                      Rates & Terms
                    </a>
                  </li>
                </ul>
                <div className="tab-content w-100">
                  <div
                    className="tab-pane fade show active"
                    id="finding-a-guarantor"
                  >
                    <FindingAGuarantor />
                  </div>
                  <div className="tab-pane fade" id="becoming-a-guarantor">
                    <BecomingAGuarantor />
                  </div>
                  <div className="tab-pane fade" id="applying-for-a-loan">
                    <ApplyingForALoan />
                  </div>
                  <div className="tab-pane fade" id="our-loans">
                    <OurLoans />
                  </div>
                </div>
                <p className="lead-2 mt-6">
                  Can’t find your answer here? Please, email us at
                  <a href="mailto:support@trustic.com"> support@trustic.com</a>.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
      <Seo
        title="Trustic | Q&A Hub"
        description="Your questions answered about guarantor personal loans from Trustic."
      />
    </>
  )
}

export default Hub
